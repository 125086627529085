<template>
  <Section class="client-logos">
  <div class="container">
			<div class="row">
				<div class="col-md-12">
					<!-- <div class="logo-divs">
  <carousel v-if="delays == true"  :rewind="false" :autoplay="true"  :autoplayTimeout="500" :autoplaySpeed="500"  :loop="true" :nav="false" :items="6" :dots="false" :responsive="{0:{items:1,nav:false},600:{items:3,nav:false},1000:{items:6,nav:false}}">
      <template slot="prev"><span class="prev">‹</span></template>
 <img v-for="(item, index) in partners" :key="index" v-bind:src="item.fullImage">
<template slot="next"><span class="next">›</span></template>
</carousel>
</div> -->


<div class="logo-divs slider ">
  <div class="carousel__wrapper">
    <img class="carousel__slide" v-for="(item, index) in partners" :key="index" v-bind:src="item.fullImage">
  </div>
  <div class="carousel__wrapper">
    <img class="carousel__slide" v-for="(item, index) in partners" :key="index" v-bind:src="item.fullImage">

  </div>
  <!-- <div class="carousel__wrapper">
    <img class="carousel__secondary" v-for="(item, index) in partners" :key="index" v-bind:src="item.fullImage">
  </div> -->
</div>

<!-- <div class="logo-divs">
  <carousel v-if="delays == true" :autoplay="true" :nav="true" :items="1" :dots="false" :responsive="{0:{items:1,nav:false},600:{items:1,nav:false},1000:{items:1,nav:false}}">
      <template slot="prev"><span class="prev">‹</span></template>
 <img v-for="(item, index) in partners" :key="index" v-bind:src="item.fullImage">
<template slot="next"><span class="next">›</span></template>
</carousel>
</div> -->
</div>
</div>
</div>
</section>
</template>
<script>

import axios from "axios";
export default {
    components: { },
     data() {
    return {
      partners: [],
      delays:false,
    };
  },
      mounted() {
    axios
      .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "carousel_slider"
        }
      })
      .then(response => {
        this.partners = response.data.customPostList;
        this.delays=true;
        console.log("partners==", this.partners);
      })
      .catch(error => {
        console.log("error", error);
      });
  }
}

</script>

<style>


.logo-divs img{
  width: auto;
    height: 46px;
    margin: 0 30px !important;

}



@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slider{
  white-space: nowrap;
  overflow: hidden;
}
.carousel__wrapper {
  display: inline-block;
  animation: 24s scroll infinite linear;
}
/* 

.carousel__wrapper {
  display: flex;
  align-items: center;
  
 
  overflow: hidden;
 
  margin: 0 auto;
}


.carousel__slide {
  animation: 5s scroll var(--iteration-time) linear infinite;
  display: flex;
  flex-direction: column;
  
  flex: 0 0 auto;
  width: var(--slide-width);
  height: var(--slide-height);
  box-sizing: border-box;
}

.carousel__image {
  background-size: cover;
  
  height: 50%;
  margin: 15px 20px;
}


    .carousel__slide {
      position: relative;
    }

    .carousel {
      counter-reset: slideNo;
    } */

    
</style>