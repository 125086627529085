<template>
  <section class="get_quote_sec">
    <div class="container">
      <h2>Get a Quote</h2>
        <form v-if="!isFormSubmitted" id="mktoForm_8581"></form>
        <div v-if="isFormSubmitted" class="form_subscribe_thanks color-white text-center">
          <unicon name="uniCheckCircle" fill="deeppink" /><p class="thanks-msg">We appreciate your confirmation!</p>
          <span
            class="d-block thanks-msg-sub"
          >One of our sales representatives will be in touch with you within the next 24 hours.</span>
        </div>
      </div>
  </section>
</template>
<script>
import { invalidDomains } from "./../../variables/commonVar";

export default {
  data() {
    return {
      isFormSubmitted: false
    };
  },
  methods: {},
  mounted() {
    window.MktoForms2.loadForm(
      "//app-lon03.marketo.com",
      "690-NGB-767",
      8581,
      form => {
        form.onSuccess(() => {
          this.isFormSubmitted = true;
          return false;
        });
      }
    );
    this.marketoReady(invalidDomains);
  }
};

</script>
