<template>
<!-- Resources Section Starts here -->
<section class="resources">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-testimonials">
          <h3 class="resources_heading"> Resources </h3>
        </div>
        <div class="tabs-resource" data-aos="fade-up" data-aos-duration="2000">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="blog-tab" data-toggle="tab" data-target="#blog-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">BLOG</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="datasheet-tab" data-toggle="tab" data-target="#datasheet-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">DATASHEET</button>
            </li>
            <!--li class="nav-item" role="presentation">
              <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Podcast</button>
               </li-->
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="brochure-tab" data-toggle="tab" data-target="#brochure-tab-pane" type="button" role="tab" aria-controls="Brochure-tab-pane" aria-selected="false">BROCHURE</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="case-tab" data-toggle="tab" data-target="#case-tab-pane" type="button" role="tab" aria-controls="Case-tab-pane" aria-selected="false">CASE STUDY</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="blog-tab-pane" role="tabpanel" aria-labelledby="blog-tab" tabindex="0">
              <div class="blog-data">
                <div class="row">
                  <div  v-bind:class="{'col-md-4':true, 'active':(id === 0)}"
          v-for="(slider, id) in blogPostSlider"
          :key="id">
                    <div class="blog-box">
                      <div class="img-blog">	
                        <img v-bind:src="`${slider._embedded['wp:featuredmedia']['0'].source_url}`">
                      </div>
                      <div class="cont-user">
                        <h4> {{slider.title.rendered }}</h4>
                        <a :href="slider.link" target="_blank" class="read-more">Read More 
                        <span class="arrow-rigt"> <img src="/img/arrow.svg" /> </span> 
                        </a>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="datasheet-tab-pane" role="tabpanel" aria-labelledby="datasheet-tab" tabindex="0">
              <div class="datasheet-data">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="data-img">
                      <img src="/img/datasheet.png">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="datasheet-cont">
                      <h5 class="sbheadr"> <span> Datasheet </span> </h5>
                      <h3> Phenomenal Storytelling Drives Excellent Results </h3>
                      <p> Know more about our engaging videos that increase engagement levels leading to more conversions. </p>
                      <a target="_blank" href="https://www.grazitti.com/assets/2019/06/Phenomenal-Storytelling-Drives-Excellent-Results-latest.pdf?utm_source=website&utm_medium=website&utm_campaign=video" class="btn-orange"><i class="fa fa-download" aria-hidden="true"></i> Download </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="brochure-tab-pane" role="tabpanel" aria-labelledby="brochure-tab" tabindex="0">
              <div class="datasheet-data">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="data-img">
                      <img src="/img/brochure.png">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="datasheet-cont">
                      <h5 class="sbheadr"> <span> Brochure </span> </h5>
                      <h3> Tell Your Brand Story With Engaging Videos </h3>
                      <p> Turn your complex business ideas into simple videos and create an emotional connection with your audience. </p>
                      <a target="_blank" href="#" class="btn-orange"><i class="fa fa-download" aria-hidden="true"></i> Download </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="case-tab-pane" role="tabpanel" aria-labelledby="case-tab" tabindex="0">
              <div class="datasheet-data">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="data-img">
                      <img src="/img/case.png">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="datasheet-cont">
                      <h5 class="sbheadr"> <span> Case Studies </span> </h5>
                      <h3> LMS-Based Training Video Modules </h3>
                      <p> Have a look at bringing down the customer’s support costs, while boosting customer engagement levels. </p>
                      <a target="_blank" href="https://pages.grazitti.com/de-cs-lms-based-training-video-modules.html?utm_source=website&utm_medium=website&utm_campaign=video" class="btn-orange"><i class="fa fa-download" aria-hidden="true"></i> Download </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Resources Section Ends here -->
</template>
<script>
import axios from "axios";
export default {
components: {},
  data() {
    return { blogPostSlider: [] };
  },
  mounted() {
    this.loader = this.showSpinner();
    axios
      .get("https://grazitti.com/wp-json/wp/v2/posts?_embed", {
        params: { categories: "1614", per_page: 3 }
      })
      .then(response => {
        this.loader.hide();
        console.log(response);
        this.blogPostSlider = response.data;
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>