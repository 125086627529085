<template>
<section class="bg-striped">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="stripd-conte">
						<h4> Create an Enthralling Experience for Your Users! </h4>
						<div class="d-flex">
						<a href="/portfolio" class="btn-orange btnCustom"> Video Portfolio </a>
						<a href="/portfolio" class="ml-3 btn-orange btn-whitish btnCustom"> E-Learning Portfolio </a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
  <script>
export default {
};
</script>

