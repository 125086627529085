<template>
<header class="main-header">
  <nav class="navbar navbar-expand-lg sticky-top">
    <div class="container">
      <a class="navbar-brand" href="#">
      <img src="/img/logo.svg">
      </a>
     <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/portfolio">Portfolio</router-link>
          </li>
          <li class="nav-item">
              <router-link class="nav-link" to="/services">Services</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/faq">FAQ</router-link>
          </li>
          <li class="nav-item">
              <router-link class="nav-link" to="/about">About Us</router-link>
          </li>
          <li class="nav-item get-quote">
           <router-link class="nav-link" to="/get-a-quote">Get a Quote</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
</template>
  <script>
export default {
};
</script>




