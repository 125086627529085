import Vue from 'vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";

Vue.use(Loading)
Vue.mixin({  
    methods: {     
        setMetaInfo(id){
            axios.post(`${process.env.VUE_APP_API}/wp/v1/page/content`, {
                params: {
                    id: id
                }
            }).then(response => {
                console.log("metaresponse", response)
                if(response.data.code==200){
                    //this.BannerContent = response.data.customPostList;
                    const titleEl = document.querySelector('head title');
                    if(titleEl)
                        titleEl.textContent  =  response.data.title;

                    const rel = document.querySelector('head link[rel="canonical"]');
                    if(rel)
                        rel.href  =  response.data.pageLink;
                    
                    const descEl = document.querySelector('head meta[name="description"]');
                    if(descEl)
                        descEl.setAttribute('content', response.data.desc);
                }
            })
            .catch(error => {
                console.log("error", error);
            });
        }
    }
  })