<template>
<section class="choose-sec">
		<div class="container">
		<h2> Why Choose Us?</h2>
			<div class="row fixspacerT justify-content-center">
<div class="col-lg-4 col-md-6 mb-4">
<div class="main-wrapper normal-box result_hover">
<div class="card mb-0">
<div class="card-header d-flex">

<img src="@/assets/images/chooseicon1.png" class="icon-main">Agile Delivery</div>
<div class="card-body">
<p class="mb-0">We employ agile processes in our video production and instructional design projects, with the aim of delivering quality work within the stipulated time frame.</p>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 mb-4">
<div class="main-wrapper normal-box">
<div class="card mb-0">
<div class="card-header d-flex">
<img src="@/assets/images/chooseicon2.png" class="icon-main">
Skilled Team
</div>
<div class="card-body">
<p class="mb-0">Each video and training module is carefully crafted by our team of talented designers, animators, and writers who possess exceptional creativity and skill.</p>
</div>
</div>
</div>
</div>
<div class="col-lg-4 col-md-6 mb-4">
<div class="main-wrapper normal-box">
<div class="card mb-0">
<div class="card-header d-flex ">
<img src="@/assets/images/chooseicon3.png" class="icon-main">
Quality Focus
</div>
<div class="card-body">
<p class="mb-0">We prioritize delivering high-quality projects through our rigorous Quality Delivery process that includes thorough analysis, collaboration, and comprehensive testing.</p>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</template>
  <script>
export default {
name: "Homewhychoose",
 props: [],
  methods: {}
};
</script>