<template>
  <section class="get-touch">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="data-get-all">
						<div class="left-cont">
							<h4> Ready to Discuss a Project? </h4>
							<p> Get in Touch With Us by Sending an Email to <a style="color:#fff" href="mailto:info@grazitti.com">info@grazitti.com</a>  </p>
						</div>
						<a href="/get-a-quote" class="btn-orange"> Get a Quote </a>
						<span class="shape1"> <img src="/img/shape1.svg" /> </span>
						<span class="shape2"> <img src="/img/shape2.svg" /> </span>
						<span class="shape3"></span>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
};
</script>
