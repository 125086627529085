import Vue from 'vue'
import { invalidDomains } from '../variables/commonVar';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading)
Vue.mixin({  
    methods: {     
        showSpinner() {
          return this.$loading.show({
              container: this.$refs.loaderContainer,
             // color: '#442779',
              color: '#19191A',
              loader: 'dots',
              width: 70,
              height: 100,
              opacity: 0.4,
          });
      },
      marketoReady() {
        window.MktoForms2.whenReady( (form) => {
            const elCountry = document.getElementById("Country");
            if(elCountry){
              elCountry.addEventListener("change", (formData) => {
                const countries = ["United States"];
                const optionCountry = formData.target.value;
                console.log("hello formData==", formData.target.value, countries);
                
                if(document.getElementById("consenttoProcessing")){
                  if(optionCountry == countries){
                      document.getElementById("consenttoProcessing").checked = true;
                  }else{
                    document.getElementById("consenttoProcessing").checked = false;
                  }
                }

                  const elState = document.getElementById("State");

                  if(elState){
                    console.log("state changed")
                    elState.addEventListener("change", (formData) => {
                      
                      const states = ["California"];
                      const optionState = formData.target.value;
                      console.log("hello formData==", formData.target.value, states);
                      if(optionState == states){
                        document.getElementById("consenttoProcessing").checked = true;
                      }else{
                        document.getElementById("consenttoProcessing").checked = false;
                      }
                    });  
                  }


                //}
              });  

              
              

            }

            
            
            form.onValidate(function(){
                const vals = form.vals();
                const ContactName = vals.fullNameEditable;
                const nameElem = form.getFormElem().find("#fullNameEditable");
                const email = vals.Email;
                if(ContactName) {
                    if( !ContactName.match('^[a-zA-Z ]{3,60}$') ) {
                        form.showErrorMessage("Enter a valid name.", nameElem);
                        form.submitable(false);
                        return false; 
                    } else {
                        const nameArr = ContactName.split(" ");
                        if(nameArr.length === 1) {
                            form.submittable(false);
                            form.showErrorMessage("Please enter full name.", nameElem);
                            return false;
                        }            
                        for( i=0; i < nameArr.length; i++ ) { 
                            if(nameArr[i] === '' || nameArr[i] === ' ') {
                                form.submittable(false);
                                form.showErrorMessage("Enter a valid name.", nameElem);
                                return false;                           
                            }
                        }
                        //Check for first name last name
                        const ContactFirstName = ContactName.substr(0,ContactName.indexOf(' '));
                        const ContactLastName = ContactName.substr(ContactName.indexOf(' ')+1);
                        //set values      
                        document.getElementsByName("FirstName")[0].value = ContactFirstName; 
                        document.getElementsByName("LastName")[0].value = ContactLastName; 
                        form.submittable(true);                       
                    }
                }
                if(email){
                    const emailRegExp =  
                    /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;  
                    const validEmail = emailRegExp.test(email);  
                    if (!validEmail) {  
                        const emailExtendedValidationError = 'Please enter a valid email address.';
                        form.showErrorMessage(emailExtendedValidationError,  
                            form.getFormElem().find("#Email")
                        );  
                        form.submittable(false); 
                    } 
                    var counter = 0;
                    for(var i=0; i < invalidDomains.length; i++) {
                        const domain = invalidDomains[i];					
                        if (email.indexOf(domain) != -1) {
                           counter = counter + 1;
                        }
                    }               
                    if(counter>0){
                        const emailElem = form.getFormElem().find("#Email");
                        form.showErrorMessage("Must be Business email.", emailElem);
                        form.submittable(false); 
                    }   
                }
            });    
        })
      }    
    }
  })