<template>
  <div class="home">
    <div class="su_page_content">
  <Homemenu />
	<Homebanner />
	<Client />
	<Homeengage />
	<Videoelearning />
	<Portfolioall />
	<Homewhychoose />
	<Testimonials />
	<Getquote />
	<Ourresourcecenter />
	<Get />
  <Ourfooter />
    </div>
  </div>
</template>
<script>

import Client from "@/components/Home/Client.vue";
import Homeengage from "@/components/Home/Homeengage.vue";
import Videoelearning from "@/components/Home/Videoelearning.vue";
import Portfolioall from "@/components/Home/Portfolioall.vue";
import Testimonials from "@/components/Common/Testimonials.vue";
import Homewhychoose from "@/components/Home/Homewhychoose.vue";
import Getquote from "@/components/Home/Getquote.vue";
import Ourresourcecenter from "@/components/Home/Ourresourcecenter.vue";
import Get from "@/components/Home/Get.vue";
import Ourfooter from "@/components/Home/Ourfooter.vue";
import Homemenu from "@/components/Home/Homemenu.vue";
import Homebanner from "@/components/Home/Homebanner.vue";
import axios from "axios";
export default {
  name: "Home",
components: {
    Client,
Portfolioall,
Ourfooter,
	Get,
	Homemenu,
Homebanner,
Testimonials,
Homeengage,
Videoelearning,
Homewhychoose,
Getquote,
  Ourresourcecenter,
  },
  data() {
    return {
      Portfolios: [],
      BannerContent: [],
      services: [],
      widgetCustomHTML: [],
      HomePortfolios: []
    };
  },
  mounted() {
    // this.getBanner();
    // this.getPortfolio();
    // this.getServices();
    // this.getQuote()
     this.setMetaInfo(99)
    
  },
  methods: {
    getBanner() {
      this.loader3 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "home-page-banner",
            custom_link_field: "cta_button",
			custom_link_field2: "cta_button2",
			
            custom_video_link: "video_url"
          }
        })
        .then(response => {
          this.loader3.hide();
          this.BannerContent = response.data.customPostList;
          console.log("BannerContent==", this.BannerContent);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getServices() {
      this.loader1 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "our_services",
            custom_link_field: "service_hover_icon"
          }
        })
        .then(response => {
          this.loader1.hide();
          this.services = response.data.customPostList;
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getPortfolio() {
      this.loader2 = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "portfolio",
            taxonomy: "portfolio_categories",
            custom_link_field: "video_link",
            featured_portfolio: "show_portfolio_on_home_page"
          }
        })
        .then(response => {
          this.loader2.hide();
          this.Portfolios = response.data.customPostList;
          for (let i = 0; i < this.Portfolios.length; i++) {
            if (this.Portfolios[i].show_on_home_page) {
              this.HomePortfolios.push(this.Portfolios[i]);
            }
          }
          console.log("Portfolios==", this.Portfolios);
        })
        .catch(error => {
          console.log("error", error);
        });
    },

    getQuote() {
      this.loader = this.showSpinner();
      axios
        .get(`${process.env.VUE_APP_API}/wp/v1/custom_widget`, {
          params: {
            type: "widget_custom_html"
          }
        })
        .then(response => {
          this.loader.hide();
          this.widgetCustomHTML = response.data.customWidgetList;
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }
};
</script>
