<template>
  <Section class=" testimonials slider-section">
  <div v-if="testimonials"  class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class=" title-testimonials">
            <h3> What Our Customers Say </h3>
          </div>
          <div class="testimaonialSlider">
  <carousel v-if="delays == true" :autoplay="true" :nav="false" :items="1" :dots="true" :loop="true" >
     
      <div
          v-bind:class="[idt == 0 ? ' active' : '']"
          class=" testimonial-item"
          v-for="(testimonial, idt) in testimonials"
          :key="idt"
        >
        <div class="item">
  <div class="box-testimonials">
          <p>{{testimonial.content}}</p>
          <div class="company-meta">
            <div class="user-person">
                <img :src="testimonial.fullImage" :alt="testimonial.custom_field_one" />
                <div class="cont-name">
                  <h5>{{testimonial.title}}</h5>
                  <p>{{testimonial.custom_text_field_one}} </p>
                </div>
              </div>
          </div>
        </div>
        </div>
        </div>

</carousel>
</div>
</div>
</div>
</div>
</section>
</template>
<script>

import carousel from 'vue-owl-carousel'
import axios from "axios";
export default {
    components: { carousel },
     data() {
    return {
      testimonials: [],
      partners: [],
      delays:false,
    };
  },
      mounted() {

        this.loader = this.showSpinner();
axios
  .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
    params: {
      post_type: "testimonial",
      custom_text_field_one: "designation"
    }
  })
  .then(response => {
    this.loader.hide();
    this.testimonials = response.data.customPostList;
  })
  .catch(error => {
    console.log("error", error);
  });
    axios
      .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "carousel_slider"
        }
      })
      .then(response => {
        this.partners = response.data.customPostList;
        this.delays=true;
        console.log("partners==", this.partners);
      })
      .catch(error => {
        console.log("error", error);
      });
  }
}

</script>

<style>
.box-testimonials .user-person img{
  width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    border: 2px solid #ff732f;
    padding: 2px;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    margin-right: 15px !important;
    margin-left: 0px !important;
}


.testimaonialSlider .owl-dots {
  text-align: center !important;
    position: relative;
    bottom: 0px;
}
  
</style>