var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"resources"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_vm._m(0),_c('div',{staticClass:"tabs-resource",attrs:{"data-aos":"fade-up","data-aos-duration":"2000"}},[_vm._m(1),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"blog-tab-pane","role":"tabpanel","aria-labelledby":"blog-tab","tabindex":"0"}},[_c('div',{staticClass:"blog-data"},[_c('div',{staticClass:"row"},_vm._l((_vm.blogPostSlider),function(slider,id){return _c('div',{key:id,class:{'col-md-4':true, 'active':(id === 0)}},[_c('div',{staticClass:"blog-box"},[_c('div',{staticClass:"img-blog"},[_c('img',{attrs:{"src":`${slider._embedded['wp:featuredmedia']['0'].source_url}`}})]),_c('div',{staticClass:"cont-user"},[_c('h4',[_vm._v(" "+_vm._s(slider.title.rendered))]),_c('a',{staticClass:"read-more",attrs:{"href":slider.link,"target":"_blank"}},[_vm._v("Read More "),_vm._m(2,true)])])])])}),0)])]),_vm._m(3),_vm._m(4),_vm._m(5)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-testimonials"},[_c('h3',{staticClass:"resources_heading"},[_vm._v(" Resources ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link active",attrs:{"id":"blog-tab","data-toggle":"tab","data-target":"#blog-tab-pane","type":"button","role":"tab","aria-controls":"home-tab-pane","aria-selected":"true"}},[_vm._v("BLOG")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"datasheet-tab","data-toggle":"tab","data-target":"#datasheet-tab-pane","type":"button","role":"tab","aria-controls":"profile-tab-pane","aria-selected":"false"}},[_vm._v("DATASHEET")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"brochure-tab","data-toggle":"tab","data-target":"#brochure-tab-pane","type":"button","role":"tab","aria-controls":"Brochure-tab-pane","aria-selected":"false"}},[_vm._v("BROCHURE")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{staticClass:"nav-link",attrs:{"id":"case-tab","data-toggle":"tab","data-target":"#case-tab-pane","type":"button","role":"tab","aria-controls":"Case-tab-pane","aria-selected":"false"}},[_vm._v("CASE STUDY")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"arrow-rigt"},[_c('img',{attrs:{"src":"/img/arrow.svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"datasheet-tab-pane","role":"tabpanel","aria-labelledby":"datasheet-tab","tabindex":"0"}},[_c('div',{staticClass:"datasheet-data"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"data-img"},[_c('img',{attrs:{"src":"/img/datasheet.png"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"datasheet-cont"},[_c('h5',{staticClass:"sbheadr"},[_c('span',[_vm._v(" Datasheet ")])]),_c('h3',[_vm._v(" Phenomenal Storytelling Drives Excellent Results ")]),_c('p',[_vm._v(" Know more about our engaging videos that increase engagement levels leading to more conversions. ")]),_c('a',{staticClass:"btn-orange",attrs:{"target":"_blank","href":"https://www.grazitti.com/assets/2019/06/Phenomenal-Storytelling-Drives-Excellent-Results-latest.pdf?utm_source=website&utm_medium=website&utm_campaign=video"}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Download ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"brochure-tab-pane","role":"tabpanel","aria-labelledby":"brochure-tab","tabindex":"0"}},[_c('div',{staticClass:"datasheet-data"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"data-img"},[_c('img',{attrs:{"src":"/img/brochure.png"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"datasheet-cont"},[_c('h5',{staticClass:"sbheadr"},[_c('span',[_vm._v(" Brochure ")])]),_c('h3',[_vm._v(" Tell Your Brand Story With Engaging Videos ")]),_c('p',[_vm._v(" Turn your complex business ideas into simple videos and create an emotional connection with your audience. ")]),_c('a',{staticClass:"btn-orange",attrs:{"target":"_blank","href":"#"}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Download ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"case-tab-pane","role":"tabpanel","aria-labelledby":"case-tab","tabindex":"0"}},[_c('div',{staticClass:"datasheet-data"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"data-img"},[_c('img',{attrs:{"src":"/img/case.png"}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"datasheet-cont"},[_c('h5',{staticClass:"sbheadr"},[_c('span',[_vm._v(" Case Studies ")])]),_c('h3',[_vm._v(" LMS-Based Training Video Modules ")]),_c('p',[_vm._v(" Have a look at bringing down the customer’s support costs, while boosting customer engagement levels. ")]),_c('a',{staticClass:"btn-orange",attrs:{"target":"_blank","href":"https://pages.grazitti.com/de-cs-lms-based-training-video-modules.html?utm_source=website&utm_medium=website&utm_campaign=video"}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Download ")])])])])])])
}]

export { render, staticRenderFns }