<template>
  <footer class="footer-main">
    <div class="container">
      <div class="row">
        <div class="col-md-4" v-if="widgetCustomHTML.length>0">
          <div
            class="left-first"
            v-html="widgetCustomHTML[2].content"
            v-if="widgetCustomHTML[2].title"
          ></div>
        </div>
        <div class="col-md-4">
        <div class="footer-col-center" v-if="widgetCustomHTML.length>0">
          <div
            class="footer-center"
            v-html="widgetCustomHTML[4].content"
            v-if="widgetCustomHTML[4].title"
          ></div>
        </div>
        </div>
        <div class="col-md-4">
        <div class="footer-col-right" v-if="widgetCustomHTML.length>0">
          <div
            class="footer-right"
            v-html="widgetCustomHTML[3].content"
            v-if="widgetCustomHTML[3].title"
          ></div>
        </div>
        </div>
      </div>
      
    </div>
    <div class="copiright-sec">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6"><p> © 2008 - {{copyrightyear}} Grazitti Interactive. All rights reserved </p></div>
          <div class="col-md-6">
          <ul class="copyrigt-menu"><li><a target="_blank" href="https://grazitti.com/sitemap"> Sitemap </a></li>
          <li><a target="_blank" href="https://www.grazitti.com/wp-content/uploads/2018/09/GRZ_EUSA-1.pdf"> EULA </a></li><li><a target="_blank" href="https://www.grazitti.com/wp-content/uploads/2018/09/GRZ_EULA-1.pdf"> EUSA </a></li>
          <li><a target="_blank" href="https://www.grazitti.com/privacy-policy/"> Privacy Policy</a></li><li><a target="_blank" href="https://www.grazitti.com/legal/terms-and-conditions/"> T&amp;C </a></li>
          </ul>
        </div>
        </div>
        </div>
    </div>
  </footer>
</template>
<script>
import axios from "axios";
const d = new Date();
export default {
  data() {
    return {
      widgetCustomHTML: [],
      copyrightyear:d.getFullYear(),
    };
  },
  mounted() {
    this.loader = this.showSpinner();
    axios
      .get(`${process.env.VUE_APP_API}/wp/v1/custom_widget`, {
        params: {
          type: "widget_custom_html"
        }
      })
      .then(response => {
        this.loader.hide();
        this.widgetCustomHTML = response.data.customWidgetList;
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>
  <style>
#santa-gif img {
  height: auto;
  max-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;
}
@media (max-width: 1199px) {
  #santa-gif,
  #santa-gif img {
    display: none;
  }
}
</style>