<template>
<section class="omni-channel-sec gz-spacer-80 engage-sec">
   <div class="container">
      <div class="row fixspacerT justify-content-center">
         <div class="col-md-12 col-lg-6 text-xl-left importance_side">
            <h2 class="mb-3"><span class="txt-orange">Engage, Inspire, and Empower</span><br> With Customized Videos and eLearning Solutions</h2>
            <p>We specialize in creating customized visual stories that bridge the gap between brands &amp; their audience. By offering unparalleled quality, we have gained the confidence of a diverse clientele that spans from startups to global conglomerates. Our services have been deployed all around the world, cementing our position as a leader in the industry.</p>
			<a href="/services" class="btn-orange"> View all Services </a>
         </div>
         <div class="col-md-12 col-lg-6 text-center"><img src="@/assets/images/engage-image.svg" class="icon-main engage_part"></div>
      </div>
   </div>
</section>
</template>
  <script>
export default {
name: "Homeengage",
 props: [],
  methods: {}
};
</script>