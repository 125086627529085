<template>
  <!-- Resources Section Starts here -->
  <section class="resources videoelearning">
    <div class="container-fluid">
    <h2> Portfolio</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="title-testimonials">
          </div>
          <div class="tabs-resource" data-aos="fade-up" data-aos-duration="2000">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">VIDEO</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">E-LEARNING</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <div class="blog-data">
                  <div class="row">
          <div class="col-md-12 aos-init aos-animate" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <div class="row">
              <div  class="mb-glt marginCustom"  v-for="(portfolio, idt) in Portfolios" v-bind:class="idt > 2? 'col-md-3'  : 'col-md-4' "  :key="idt">
                <div class="img-box-1">
                  <a v-on:click="popupVideoModal(portfolio.custom_link)"  class="img-ligt" data-fancybox="video-gallery"> 
                    <img :src="portfolio.fullImage">
                    <div class="hover-box">
                      <span :class="`play-icon ${idt}`" > <i class="fa fa-play" aria-hidden="true"></i> </span>
                      <div class="hover-box-title mt-3"> {{portfolio.title | truncate(15) }}
</div>
                
                    </div>
                  </a>
                </div>
              </div>
              </div>
            </div>
          </div>
                </div>
              </div>
              <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                <div class="elearning">
                  <div class="row align-items-center">
                    <div>
        
  
        
    
    </div>
              
     <div class="mb-glt col-md-4"   v-for="(image, index) in media" :key="index">
        <div class="img-box-1" @click="openGallery(index)">
           <a  class="img-ligt">
              <img id="elearning-1" :src="image.src" 
              >
              <div class="hover-box">
                <img class="hover_effect" src="@/assets/images/hover_effect.svg">
                 <div class="hover-box-title">Gigamon</div>
                <p class="hover-box-tag">Software Development</p>
              </div>
           </a>
        </div>
     </div>
     
    
     
   
  
  </div>
                </div>
              </div>         
            </div>
          </div>
        </div>
      </div>
      <sweet-modal id="port_vid" ref="modal" modal-theme="dark" overlay-theme="dark">
        <div class="close" v-on:click="closePopup()">Close</div>
        <iframe id="frm"
          width="560"
          height="315"
          :src="'https://www.youtube.com/embed/MmFWOU2cUPA'"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <!-- <video :id="'youtubeVideo-'+PortfolioItem.id" :src="PortfolioItem.custom_link" controls>
          <source :src="PortfolioItem.custom_link" />Your browser does not support the video tag.
        </video>-->
      </sweet-modal>
      <sweet-modal class="elearning-popup" ref="modal2">
        <div class="outer-m-elearning outerPortfolio">
          <p id="elearning-title" class="titlePortfolio"></p>
          <img src="@/assets/images/close-icon.png" class="close-elearning" v-on:click="closePopup2()">
          <img src="" id="frml2">
       </div>
      
  
       <!-- <div class="imgSliders d-flex">
        <div v-for="(imageUrl, index) in imageUrls" :key="index">
        <img :src="imageUrl" alt="Image" />
      </div>
       </div> -->
      
  <!-- 
       <div class="carousalData">
        <div class="item" v-for="(imageUrl, index) in imageUrls" :key="index">
          <img :src="imageUrl" alt="Image" class="imageDimension" />
        </div>
       </div> -->
      </sweet-modal>
    </div>
    <LightBox
          ref="lightbox"
          :media="media"
          :show-caption="true"
          :show-light-box="false"
        />
  </section>
  </template>
    <script>
    import LightBox from 'vue-it-bigger'
    import axios from "axios";
    require('vue-it-bigger/dist/vue-it-bigger.min.css');
    
  export default {
    name: "Portfolio",
    components: {
      LightBox,
    },
    data() {
      return {
        Portfolios: [],
        imageUrls: [],
        media :[
    { // For image
      thumb: require('@/assets/images/elearning1.png'),
      src:  require('@/assets/images/elearning1.png'),
      caption: 'caption to display. receive <html> <b>tag</b>', // Optional
      srcset: require('@/assets/images/elearning1.png') // Optional for displaying responsive images
    },
    { // For image
      thumb: require('@/assets/images/elearning2.png'),
      src:  require('@/assets/images/elearning2.png'),
      caption: 'caption to display. receive <html> <b>tag</b>', // Optional
      srcset: require('@/assets/images/elearning2.png') // Optional for displaying responsive images
    },
    { // For image
      thumb: require('@/assets/images/elearning3.png'),
      src:  require('@/assets/images/elearning3.png'),
      caption: 'caption to display. receive <html> <b>tag</b>', // Optional
      srcset: require('@/assets/images/elearning3.png') // Optional for displaying responsive images
    },
    { // For image
      thumb: require('@/assets/images/elearning4.png'),
      src:  require('@/assets/images/elearning4.png'),
      caption: 'caption to display. receive <html> <b>tag</b>', // Optional
      srcset: require('@/assets/images/elearning4.png') // Optional for displaying responsive images
    },
    { // For image
      thumb: require('@/assets/images/elearning5.png'),
      src:  require('@/assets/images/elearning5.png'),
      caption: 'caption to display. receive <html> <b>tag</b>', // Optional
      srcset: require('@/assets/images/elearning5.png') // Optional for displaying responsive images
    },
    { // For image
      thumb: require('@/assets/images/elearning6.png'),
      src:  require('@/assets/images/elearning6.png'),
      caption: 'caption to display. receive <html> <b>tag</b>', // Optional
      srcset: require('@/assets/images/elearning6.png') // Optional for displaying responsive images
    },
   
    
  ]
        
        
      };
    },
    mounted() {
      this.getPortfolio();
      this.newFun();
      this.popupFetch();
  
     
    },
    methods: {
      updatePortfolio(data) {
        this.Portfolios = data;
      },
      getPortfolio() {
        this.loader1 = this.showSpinner();
        axios
          .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
            params: {
              post_type: "portfolio",
              taxonomy: "portfolio_categories",
              custom_link_field: "video_link",
        limit: 7	
            }
          })
          .then(response => {
            this.loader1.hide();
            this.Portfolios = response.data.customPostList;
            console.log("Portfolios==", this.Portfolios);
          })
          .catch(error => {
            console.log("error", error);
          });
      },
     popupVideoModal(par) {
       document.getElementById('frm').src = par;
        this.$refs.modal.open();
      },
      closePopup() {
       document.getElementById('frm').src = "";
        this.$refs.modal.close();
      },
      
  
      popupElearningModal(par,title) {
  //const box = document.getElementById('frm');
  
     // 👇️ removes element from DOM
    // box.style.display = 'none';
     
     var ImageURL = document.getElementById(par).src;
  
     document.getElementById('elearning-title').innerHTML = title;
     document.getElementById('frml2').src = ImageURL;
        this.$refs.modal2.open();
      },
      closePopup2() {
       document.getElementById('frml2').src = "";
        this.$refs.modal2.close();
      },
      newFun(){
        var d = document.getElementById("port_vid");
        d.onclick = function(){  document.getElementById('frm').src = "";}
      },
      popupFetch() {
       
        // var parent = document.getElementsByClassName('img-box-1');
    
      var  parent = document.querySelectorAll('.img-box-1');
      // var img = [...parent.querySelectorAll('img')];
      var imgArr = []
      for(var i=0; i<parent.length; i++){
        console.log(parent[i])
        var imgFetch = parent[i].querySelector('img');
        imgArr.push(imgFetch.getAttribute('src'))
        console.log(imgFetch.getAttribute('src'))
        
      }
      this.imageUrls = imgArr
      console.log(this.imageUrls, 'imageurls are')
  
      //  parent.map((ref)=>{console.log(ref.outerHtml)})
  
       
      },
      openGallery(index) {
        this.$refs.lightbox.showImage(index)
      }
    }
  };
  
  
  </script>
  
  <style>
  .sweet-modal.theme-light.has-content.is-mobile-fullscreen.is-visible.is-alert {
      min-width: 100%;
      background: none !important;
      display: flex;
      justify-content: center;
      height: 100%;
      background: none !important;
  }
  .sweet-content {
      /* width: 100%; */
      justify-content: center !important;
      display: flex !important;
      background: none !important;
  }
  
  .outerPortfolio {
      padding: 11px;
      width: 750px;
      margin: auto;
      background: #dddddd;
      border-radius: 0px !important;
  }
  
  img.imageDimension {
      width: 200px;
      height: auto;
      margin-left: 13px;
      border-radius: 5px;
  }
  
  .carousalData{
    display: flex;
      width: 79%;
      overflow-y: scroll;
      padding: 9px;
      margin: auto;
      margin-top: 30px;
  }
  /* Customize the carousel item style (optional) */
 
  </style>
     
     
      
  