import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'es6-promise/auto'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './mixins/global'
import './mixins/metaInfo'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();
import Unicon from 'vue-unicons'
Vue.use(Unicon)
import { uniLayerGroupMonochrome, uniArrowRight, uniArrowLeft, uniCheckCircle } from 'vue-unicons/src/icons'
Unicon.add([uniLayerGroupMonochrome, uniArrowRight, uniArrowLeft, uniCheckCircle])
import 'slick-carousel/slick/slick.css'

import SweetModal from 'sweet-modal-vue/src/plugin.js'
Vue.use(SweetModal)

import Carousel3d from 'vue-carousel-3d'
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad)

Vue.use(Carousel3d);
// Filter for trimming the title of blog in resource blogs slider
var TitleTrim = function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', TitleTrim);
// ***********************************************************


var cookieName = localStorage.getItem("cookie:accepted");
if (cookieName) {
  document.querySelector('body').classList.add('cookie-set');
} else {
  document.querySelector('body').classList.add('cookie-not-set');
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
