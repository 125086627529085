<template>
   <Section class=" slider-section banner-section">

                <div class="carouselMain">
   <carousel v-if="delays == true" :autoplay="false" :nav="false" :items="1" :dots="true" >
       <template slot="prev"><span class="prev"></span></template>
       <div class="bg-gradient-banner bghom bannerCover">
         <img class="objectPatteren" src="@/assets/images/object.gif" alt="">
                  <div class="container">
                     <div class="row align-items-center justify-content-between">
                        <div class="col-md-7 mrgn-sets">
                           <div class="content-banner-s">
                              <h5>Phenomenal Storytelling  <span> Drives Excellent Results </span> </h5>
                              <a href="/get-a-quote" class="btn-banner"> Let’s Get Started </a>
                           </div>
                        </div>
                       

                        <div class="col-md-6" style="position: relative;">
                        
                                    <div  class="video-thumb-banner" >
                                       <div class="videoCoverImage">
                              <!-- <div onclick="thevid=document.getElementById('thevideo'); thevid.style.display='block'; this.style.display='none'">
                                 <img class="thumb w-100" style="cursor: pointer;" src="@/assets/images/banner.gif" >
                                 <a href="javascript:void(0);" class="play-icon-po">
                                 <small> 
                                 <img src="@/assets/images/play.svg">
                                 </small> 
                                 <div class="waves wave-1"></div>
                                 <div class="waves wave-2"></div>
                                 <div class="waves wave-3"></div>
                              </a>
                              </div>
                           
                              <div id="thevideo" style="display: none;" class="embedVideo">
                                 <iframe id="bnfrm"
                              width="100%"
                              height="358"
                              :src="'https://www.youtube.com/embed/MmFWOU2cUPA?rel=0;&autoplay=0&mute=1&loop=1'"
                              frameborder="0"
                              allowfullscreen
                              ></iframe>
                              </div> -->
                              

                              <div>
                                 <img
                                 v-if="!showVideo"
                                 src="@/assets/images/banner.gif"
                                 alt="Custom Thumbnail"
                                
                                 class="w-100"
                                 />
                                 <a   @click="playVideo"
                                 style="cursor: pointer" class="play-icon-po">
                                 <small> 
                                 <img src="@/assets/images/play.svg">
                                 </small> 
                                 <div class="waves wave-1"></div>
                                 <div class="waves wave-2"></div>
                                 <div class="waves wave-3"></div>
                              </a>
                              <div id="thevideo" class="embedVideo" v-if="showVideo">
                                 <iframe
                                 
                                 width="100%"
                              height="358"
                                 :src="videoSrc"
                                 frameborder="0"
                                 allow="autoplay; encrypted-media"
                                 allowfullscreen
                                
                                 ></iframe>
                              </div>
                                
                              </div>
                           </div>
                             
                           </div>
                           <img class="patterenDots" src="@/assets/images/patteren3.png" alt="">
                           <img class="dotPatteren" src="@/assets/images/patteren3.png" alt="">

                        </div>
                        
                     </div>
                  </div>
                  <img class="imagePatteren" src="@/assets/images/object.gif" alt="">
                  
                

               </div>

<div class="bg-gradient-banner bannerCover">
            <div class="container">
               <div class="row align-items-center justify-content-between">
                  <div class="col-md-7 mrgn-sets">
                     <div class="content-banner-s">
                        <h5>Transform Your <span> Elearning Experience </span> and Elevate It To The Next Level</h5>
                        <a href="/get-a-quote" class="btn-banner"> Let’s Get Started </a>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="img-banner-s">
                        <img src="@/assets/images/banner-image.png" class="w-100 customDimension">
                        <span class="round-vector customDimension"> <img src="@/assets/images/round.svg" class="customDimension"> </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>


 <template slot="next"><span class="next"></span></template>
 </carousel>
 </div>
 
 
 
 </section>
 </template>
 <script>
 import carousel from 'vue-owl-carousel'
 import axios from "axios";
 export default {
     components: { carousel },
      data() {
     return {
      showVideo: false,
       partners: [],
       delays:false,
     };
   },
       mounted() {
     axios
       .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
         params: {
           post_type: "carousel_slider"
         }
       })
       .then(response => {
         this.partners = response.data.customPostList;
         this.delays=true;
         console.log("partners==", this.partners);
       })
       .catch(error => {
         console.log("error", error);
       });

       this.getBanner();
    this.newFun();
   
   },
   methods: {

      playVideo() {
      this.showVideo = true;
    },
getBanner() {
 this.loader3 = this.showSpinner();
 axios
   .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
     params: {
       post_type: "header_banner",
       taxonomy: "banner_type",
       cat_name: "home-page-banner",
       custom_link_field: "cta_button",
       custom_video_link: "video_url"
     }
   })
   .then(response => {
     this.loader3.hide();
     this.BannerContent = response.data.customPostList;
     console.log("BannerContent==", this.BannerContent);
   })
   .catch(error => {
     console.log("error", error);
   });
},
getIndex(i, len) {
 return i === (len - 1) ? 0 : i + 1
},
popupVideoModalhome() {
 document.getElementById('bnfrm').src = "https://www.youtube.com/embed/MmFWOU2cUPA";
 this.$refs.modal.open();
},
closePopup() {
 document.getElementById('bnfrm').src = "";
 this.$refs.modal.close();
 
},
newFun(){
 var d = document.getElementById("home_vid");
 d.onclick = function(){  document.getElementById('bnfrm').src = "";}
},

},
computed: {
    videoSrc() {
      return this.showVideo ? "https://www.youtube.com/embed/MmFWOU2cUPA?autoplay=1&rel=0" : "";
    }
  },

 }
 
 
 </script>
 
 <style>
.owl-carousel .owl-item img{
   width: 100% !important;
   height: unset !important;
}

.owl-carousel .owl-item small img{
   width: unset !important;
}
/* .owl-theme .owl-nav.disabled + .owl-dots{
   display: block !important;
    position: absolute;
    bottom: 144px !important;
    left: 88px;
}
 */

.owl-theme .owl-dots .owl-dot.active span{
   width: 48px;
    height: 12px;
    left: calc(50% - 96px/2 - 43px);
    background: #0092FF;
    border-radius: 50px;
    background-color: #FF732F !IMPORTANT;
    border-bottom: 0;
    border-top: 0;
    transition: 0.5s;
  
}
.owl-theme .owl-dots .owl-dot span{
   width: 14px;
   height: 14px;

  
}
.owl-dots {
    position: relative;
    bottom: 231px;
    margin: 0 auto auto 0;
    text-align: left !important;
    padding: 0px 10px;
   margin-left: auto !important;
}
.bg-gradient-banner.bghom {
    background-repeat: no-repeat !important;
    background-size: cover !important;
}


@media only screen and (max-width: 768px) {
 
  .owl-carousel .owl-item img.customDimension {
      width: 67% !important;
    position: relative;
    left: -15px;
}
.owl-carousel .owl-item .round-vector img.customDimension {
      width: 67% !important;
    position: relative;
    left: -67px;
}
.owl-dots {
    bottom: 47px;
}
}


@media only screen and (min-width: 576px) {
 
   .owl-dots {
    max-width: 540px;
}
}
@media only screen and (min-width: 768px) {
   .owl-dots {
    max-width: 720px;
   bottom: 123px;
}



}
@media only screen and (min-width: 992px) {
   .owl-dots {
    max-width: 960px;
}
}
@media only screen and (min-width: 1200px) {
   .owl-dots {
    max-width: 1320px;
}

}



</style>